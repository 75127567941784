/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Observable, from } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
export function fromTask(task) {
    return new Observable(function (subscriber) {
        var progress = function (snap) { return subscriber.next(snap); };
        var error = function (e) { return subscriber.error(e); };
        var complete = function () { return subscriber.complete(); };
        // emit the current state of the task
        progress(task.snapshot);
        // emit progression of the task
        var unsubscribeFromOnStateChanged = task.on('state_changed', progress);
        // use the promise form of task, to get the last success snapshot
        task.then(function (snapshot) {
            progress(snapshot);
            setTimeout(function () { return complete(); }, 0);
        }, function (e) {
            progress(task.snapshot);
            setTimeout(function () { return error(e); }, 0);
        });
        // the unsubscribe method returns by storage isn't typed in the
        // way rxjs expects, Function vs () => void, so wrap it
        return function unsubscribe() {
            unsubscribeFromOnStateChanged();
        };
    }).pipe(
    // since we're emitting first the current snapshot and then progression
    // it's possible that we could double fire synchronously; namely when in
    // a terminal state (success, error, canceled). Debounce to address.
    debounceTime(0));
}
export function getDownloadURL(ref) {
    return from(ref.getDownloadURL());
}
// TODO: fix storage typing in firebase, then apply the same fix here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMetadata(ref) {
    return from(ref.getMetadata());
}
export function put(ref, data, metadata) {
    return new Observable(function (subscriber) {
        var task = ref.put(data, metadata);
        return fromTask(task).subscribe(subscriber).add(task.cancel);
    }).pipe(shareReplay({ bufferSize: 1, refCount: true }));
}
export function putString(ref, data, format, metadata) {
    return new Observable(function (subscriber) {
        var task = ref.putString(data, format, metadata);
        return fromTask(task).subscribe(subscriber).add(task.cancel);
    }).pipe(shareReplay({ bufferSize: 1, refCount: true }));
}
export function percentage(task) {
    return fromTask(task).pipe(map(function (s) { return ({
        progress: (s.bytesTransferred / s.totalBytes) * 100,
        snapshot: s
    }); }));
}
